import React from 'react'
import logo from '../images/logo.svg'
import headerStyles from './tasksHeader.module.css'

export default () => {
  return  (
    <header className={headerStyles.header}>
      <img className={headerStyles.logo} src={logo} alt="Community Tasks" />
    </header>
  )
}
