import React, {useState} from 'react';
import Modal from 'react-modal';
import styles from './task.module.css';
import moment from 'moment';

export default ({task}) => {
  const [showModal, setShowModal] = useState(false);
  return  (
    <li>
      <div className={styles.task}>
        <h3>{task.displayType}</h3>
        <h4>{moment(task.createdAt).fromNow()} | {task.taskId}</h4>
        <p className={styles.description}>{task.transcriptionText}</p>
        <button className={styles.helpButton} onClick={() => setShowModal(true)}>I can help!</button>
        <Modal
          isOpen={showModal}
          appElement={document.getElementById('___gatsby')}
          style={{
            overlay: {
              background: 'rgba(0, 0, 0, 0.4)',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center'
            },
            content: {
              margin: '1em',
              position: 'static',
              background: '#FFFFFF',
              boxShadow: '0px 15px 14px rgba(0, 0, 0, 0.25)',
              borderRadius: '1.5em',
              overflow: 'auto',
              padding: '1em'
            }
          }}
        >
          <h3 className={styles.modalHeader}>That's Great!</h3>
          <div className={styles.content}>
            <p>If you think you can help this person, complete the following steps:</p>
            <ul>
              <li>Call 1-855-90-TASKS and press 2 when prompted</li>
              <li>When prompted for a 10 digit code, enter the digits <span className={styles.taskId}>{task.taskId}</span></li>
              <li>Stay on the line and the CommunityTasks hotline will automatically connect you to the task creator.</li>
            </ul>
            <button className={styles.helpButton} onClick={() => setShowModal(false)}>
              Thanks, I've got it
            </button>
          </div>
        </Modal>
      </div>
    </li>
  )
}
