import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Task from './task';
import styles from './taskList.module.css';
import { navigate } from "gatsby";

const navigateToHome = () => {
  navigate('/')
}

const NoTasks = () => (
  <div>
    <p className={styles.noResults}>We're sorry, it looks like there are no tasks in your area.</p>
    <div>
      <button className={styles.back} onClick={navigateToHome}>Back</button>
    </div>
  </div>
)

export default () => {
  const [tasks, setTasks] = useState([]);
  const [zipCode, setZipCode] = useState();
  const [city, setCity] = useState();
  const [state, setState] = useState();
  const [nextToken, setNextToken] = useState();

  const fetchMore = async () => {
    const {data} = await axios.get('https://api.communitytasks.org/api/tasks', {
      params: {
        zip: zipCode,
        city,
        state,
        nextToken
      }
    });

    setTasks([...tasks, ...data.items]);
  }

  const getTasks = async ({zipCode, city, state}) => {
    const {data} = await axios.get('https://api.communitytasks.org/api/tasks', {
      params: {
        zip: zipCode,
        city,
        state
      }
    });

    console.log('items is: ', data.items);

    setTasks(data.items);
    setState(state);
    setCity(city);
    setZipCode(zipCode);

    if (data.nextToken) {
      setNextToken(data.nextToken);
    }
  }

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const zipCode = urlParams.get('zipCode');
    const city = urlParams.get('city');
    const state = urlParams.get('state');
    getTasks({zipCode, city, state});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getTasksArea = () => {
    if (state && !city) {
      return state;
    }
    if (state && city) {
      return city + ', ' + state;
    }
    if (zipCode) {
      return zipCode
    }
  }

  return  (
    <div className={styles.container}>
      <h2 className={styles.h2}>{tasks.length} result{tasks.length > 1 ? 's' : ''} in {getTasksArea()}</h2>
      <ul className={styles.taskList}>
        {tasks.length ? tasks.map(task => <Task key={task.originCallSid} task={task}/>): <NoTasks />}
      </ul>
      { nextToken ? <button className={styles.fetchMore} onClick={fetchMore}>Load more</button> : '' }
    </div>
  )
}
